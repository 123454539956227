@import "../../assets/styles/utils";

.comp-root{
    height: 100vh;
    overflow-y: hidden;
}

.page-index{
    .header .nav-bg{
        @include res(height, 664px,(md:438px,xs:292px));
      &.active{
          height: 0;
      }
    }
}

#index_banner {
    position: relative;
    .line {
        width: 1px;
        background: rgba(255,255,255,.2);
        position: absolute; 
        bottom: 0;
        z-index: 2;
        @include res(left,7.91%);
        @media screen and (max-width: 2328px) {height: 60%;}
        @media screen and (max-width: 2048px) {height: 60%;}
        @media screen and (max-width: 1920px) {height: 63%;}
        @media screen and (max-width: 1680px) {
            @include res(height,61%,(lg:62%,mmd:63%,md:65%,sm:69%,xs:68%));
        }
        &::after{
            content: '';
            display: block;
            width: 1px;
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            @include res(height, 49px, 30 / 49);
        }
    }
    .index-banner {
        position: relative;
        @include res(height, 100vh, (md:600px, sm:400px, xs:300px));
        .swiper-wrapper {
            .swiper-slide {
                overflow: hidden;
                display: flex;
                align-items: center;
                &.swiper-slide-active,
                &.swiper-slide-duplicate-active {
                    .slide-inner {
                        b {
                            transform: scale(1);
                        }
                    }
                }
                .slide-inner {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: -1;
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,.2);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    b {
                        display: block;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: transform 4s;
                        transition-delay: .5s;
                        transform: scale(1.1);
                        transform-origin: center;
                        &.video-img{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            @include res(display,none,(md:block));
                        }
                    }
                    video{
                        display: block;
                        height: 100%;
                        width: 100%;
                        transform: scale(1.3);
                        transform-origin: center;
                        @include res(display,block,(md:none));
                    }
                }
                .title {
                    display: flex;
                    position: absolute;
                    @include res(left,7.91%);
                    
                    .right {
                        margin-left: 40px;
                        p {
                            @include res(width,100%,(lg:90%,mmd:80%,md:70%));
                            img {
                                max-width: fit-content;
                                width: 100%;
                            }
                        }
                    }
                    h2 {
                        width: fit-content;
                        font-family: DIN-Medium;
                        @include res(font-size,40px,26 / 40);
                        background-image: linear-gradient(to right, #FFF4E5,#F4B975);
                        background-clip: text;
                        color: transparent;
                        margin-bottom: 15px;
                    }
                    .shubiao {
                        color: #fff;
                        display: flex;
                        align-items: center;
                        .rain-drop {
                            box-sizing: border-box;
                            width: 16px;
                            height: 24px;
                            border-radius: 15px;
                            border: 1px solid #fff;
                            background: rgba(0, 0, 0, 0);
                            z-index: 1;
                            overflow: hidden;
                            transform: translate3d(-50%, 0, 0);
                            span {
                                display: block;
                                width: 2px;
                                height: 6px;
                                border-radius: 5px;
                                background: #fff;
                                margin: 10px auto 0;
                                animation: rain 1.5s infinite;
                                }
                            }
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    .swiper-button-prev,.swiper-button-next {
        color: #fff;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        top: auto;
        @include res(bottom, 90px, 30 / 90);
        @include res(width,60px, 34 / 60);
        @include res(height, 60px, 34 / 60);
        i {
            @include res(font-size,18px, 12 / 18);
        }
        &:after {
            content: normal;
        }
        &:hover{
            background-color: #fff;
            color: $color-main-brown;
        }
    }
    .swiper-button-prev {
        @include res(left,10.52%,(lg:9.52%,xs:66px));
    }
    .swiper-button-next {
        @include res(left,14.58%,(mmd:16%,xs:110px));
        right: auto;
    }
    .swiper-pagination {
        @include res(right, 7.29%,(lg:8%,mmd:9%,md:7%,sm:9%,xs:10%));
        z-index: 100;
        @include res(top, 316px, (md:50%));
        li {
            &.swiper-pagination-bullet {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                border: solid 1px transparent;
                display: flex!important;
                align-items: center;
                justify-content: center;
                transition: all .3s;
                background: transparent;
                opacity: 1;
            }

            span {
                opacity: 1;
                width: 6px;
                height: 6px;
                background-color: #fff;
                position: relative;
                border-radius: 50%;
                transition: all .3s;
                display: block !important;
            }

            &:not(:last-child) {
                @include res(margin-bottom, 2px);
            }

            &.swiper-pagination-bullet-active {
                border-color: #fff;
            }
        }
    }
}
.index_news {
    @include res(height,960px,(sm:fit-content));
    flex-wrap: wrap;
    display: flex;
    .left {
        @include res(width, 67.5%,(sm:100%));
        background: #FEF7EE;
        display: flex;
        flex-wrap: wrap;
        .headline {
            @include res(width,47.9%,(sm:100%));
            @include res(height,840px,(sm:fit-content));
            background: #C78F42;
            @include res(padding-top, 107px,(sm:30px));
            @include res(padding-bottom, 0px, (sm:30px));
            @include res(padding-left,8.48%);
            box-sizing: border-box;
            .pic {
                position: relative;
                overflow: hidden;
                @include res(padding-right,0,(xs:30px));
                img {
                    display: block;
                    transition: all .3s;
                    width: 100%;
                }
                &:hover {
                    img {
                        transform:scale(1.1);
                    }
                }
                .newsicon {
                    position: absolute;
                    top: 16px;
                    left: 11px;
                }
            }
            .text {
                color: #ffffff;
                @include res(padding-right,50px);
                .time {
                    @include res(margin-top, 30px);
                    font-family: DIN-Medium;
                    @include res(font-size,14px, 12 / 14);
                }
                h2 {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 2; 
                    @include res(margin-top, 12px);
                    @include res(font-size, 22px, 16 / 22);
                    @include res(line-height,1.8, 1.6 / 1.8);
                }
                p {
                    @include res(margin-top, 30px);
                    @include res(font-size, 16px, 14 / 16);
                    @include res(line-height, 2, 1.6 / 2);
                }
            }
            a {
                position: relative;
                
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #fff;
                float: right;
                @include res(padding-bottom,5px, 5 / 5);
                @include res(width,162px,);
                @include res(margin-top, 50px,(sm:20px));
                @include res(margin-right,50px);
                span {
                    display: block;
                    font-size: 14px;
                    transition: all .3s;
                }
                i {
                    display: block;
                    transition: all .3s;
                }
                &:before {
                    content:'';
                    width: 100%;
                    height: 1px;
                    background: #fff;
                    position: absolute;
                    bottom: -5px;
                }
                &:hover {
                    span {
                        transform: translateX(5px);
                    }
                    i {
                        transform: translateX(-5px);
                    }
                }
            }
        }
        .news-list {
            @include res(width, 52.1%,(sm:100%));
            box-sizing: border-box;
            @include res(margin-top, 170px,(sm:30px));
            @include res(padding-bottom, 0px, (sm:30px));
            @include res(padding-left,6.48%);
            @include res(padding-right, 6.01%);
            .search-box {
                width: 100%;
                position: relative;
                z-index: 1;
                input {
                    &[type='text'] {
                        display: block;
                        width: 100%;
                        @include res(height,40px);
                        outline: none;
                        border: none;
                        background: transparent;
                        border-bottom: 1px solid #157574;
                        padding-bottom: 10px;
                        @include res(font-size,18px, 16 / 18);
                        color: rgba(0,0,0,.6);
                        &::-webkit-input-placeholder {
                        color: rgba(0, 0, 0, .6);
                        }
                        &:-moz-placeholder {
                        color: rgba(0, 0, 0, .6);
                        }
                        &::-moz-placeholder {
                            color: rgba(0, 0, 0, .6);
                        }
                        &:-ms-input-placeholder {
                            color: rgba(0, 0, 0, .6);
                        }
                    }
                }
                button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border: none;
                    outline: none;
                    background: transparent;
                    cursor: pointer;
                    i {
                        font-size: 22px;
                    }
                }
            }
            ul {
                li {
                    @include res(margin-top,60px,(sm:20px));
                    a {
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        line-clamp: 2;
                        -webkit-line-clamp: 2;
                        @include res(margin-top, 15px);
                        @include res(font-size,22px, 16 / 22);
                        color: rgba(0,0,0,.8);
                        transition: all .3s;
                        @include res(line-height, 1.8, 1.6 / 1.8);
                        &:hover {
                            color: #157574;
                        }
                    }
                }
            }
        }
    }
    .right {
        @include res(padding-top, 160px,(md:30px));
        @include res(padding-left, 4%);
        @include res(padding-right, 2.65625%);
        box-sizing: border-box;
        @include res(width, 32.5%,(sm:100%));
        background: #fff;
        .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include res(margin-bottom,55px);
            h2 {
                @include res(font-size, 40px, 26 / 40);
                color: #b07c35;
                font-weight: bold;
            }
            a {
                @include res(font-size, 16px, 14 / 16);
                position: relative;
                color:rgba(0, 0, 0, .6);
                transition: all .3s;
                &:before,
                &:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    top: auto;
                    bottom: -5px;
                    height: 2px;
                    background-color: #000;
                    background-color: #000000;
                    opacity: 0.3;
                }
                &:after {
                    width: 0;
                    left: auto;
                    right: 0;
                    opacity: 1;
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                    background-color: #fff;
                }
            
                &:hover {
                    color: #b07c35;
                    &:after {
                        width: 100%;
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
        ul {
            li {
                @include res(margin-top,25px);
                border-bottom: 1px solid rgba(0,0,0,.1);
                padding-bottom: 15px;
                .time {
                    @include res(font-size,16px, 14 / 16);
                    font-family: DIN-Medium;
                    color: #157574;
                }
                a {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    @include res(font-size, 18px, 16 / 18);
                    color: rgba(0,0,0,.8);
                    @include res(line-height, 1.8, 1.6 / 1.8);
                    transition: all .3s;
                    &:hover {
                        color: rgba($color: #157574, $alpha: 0.8);
                    }
                }
            }
        }
    }
}

.index-about{
    @include res(margin-top, 120px, 30 / 120);
    .cont1{
        overflow: hidden;
        position: relative;
        @include res(padding-bottom,190px, (sm:0px));
        @include res(padding-left, 13.7%, 2 / 13.7);
        @include res(padding-right, 18.75%, 2 / 18.75);
        h1{
            font-weight: bold;
            color: $color-main-green;
            @include res(font-size,40px, 26 / 40);
            @include res(margin-bottom,60px, 20 / 60);
        }
        .cont{
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            @include res(display,flex,(sm:block));
            .left{
                flex-shrink: 0;
                @include res(line-height,2, 1.6 / 2);
                @include res(font-size,18px, 14 / 18);
                @include res(width, 50.7%,(md:40%,sm:100%));
                @include res(margin-bottom,null,(sm:50px,xs:30px));
            }
            .right{
                flex-shrink: 0;
                width: 50%;
                @media (max-width: 1600px){
                    width: 60%;
                }
                @media (max-width: 1380px){
                    width: 80%;
                }
                @media (max-width: 1370px){
                    width: 70%;
                }
                @media (max-width: 1024px){
                    width: 75%;
                }
                @media (max-width: 991px){
                    width: 100%;
                }
                @include res(margin-left, 8%,(sm:0%));
                .num-cont {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .num-item {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        @include res(width, 50%, (mmd:60%, sm:50%));
                        &:nth-child(1),&:nth-child(3) {
                            @include res(width, 50%, (mmd:40%,sm:50%));
                        }
                        @include res(margin-bottom,50px);
                        // &:nth-child(2) {
                        //     .item-top {
                        //         .num {
                        //             .dataLine {
                        //                 width: 20px!important;
                        //             }
                        //         }
                        //     }
                        // }
                        .item-top {
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-start;
                            @include res(margin-bottom, 20px, 10 / 20);
                            position: relative;
                            .num {
                                position: relative;
                                color: $color-main-brown;
                                @include res(line-height,82px, 30 / 82);
                                @include res(font-size,82px, 30 / 82);
                                .number-animate{
                                    overflow: hidden; 
                                    display: inline-block; 
                                    position: relative; 
                                    @include res(line-height,82px, 30 / 82);
                                    @include res(height,82px, 30 / 82);
                                    @include res(font-size,82px, 30 / 82);
                                }
                                .number-animate .number-animate-dot{
                                    width: 21px; 
                                    float: left; 
                                    text-align: center;
                                }
                                .number-animate .number-animate-dom{  
                                    text-align: center; 
                                    float: left; 
                                    position: relative; 
                                    top: 0;
                                    @include res(width,46px, 16 / 46);
                                }
                                .number-animate .number-animate-dom span,.number-animate .number-animate-dot span{
                                    float: left;
                                    width: 100%;
                                    line-height: 1.1;
                                    @include res(height,82px, 30 / 82);
                                }
                            }
                            .dw {
                                font-family: DIN-Medium;
                                @include res(font-size,50px, 20 / 50);
                                color: #c78f42;
                                position: absolute;
                                @include res(top,-25px,(sm:-15px));
                                @include res(right, -35px, (sm:-25px));
                            }
                        }
                        .item-desc {
                            width: 100%;
                            font-weight: bold;
                            @include res(font-size, 18px, 14 / 18);
                            color: #115e5d;
                        }
                        
                    }
                }
            }
        }
        .right-img{
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 0;
            @include res(display,block,(sm:none));
            @include res(width,25.88%);
            img{
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .center-img{
            position: absolute;
            bottom: 5%;
            right: 48%;
            z-index: 0;
            @include res(display,block,(sm:none));
            @include res(width,9.3%);
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .cont2{
        position: relative;
        align-items: flex-start;
        justify-content: space-between;
        @include res(display,flex,(sm:block));
        @include res(padding-top,105px, 30 / 105);
        @include res(padding-bottom,130px, 30 / 130);
        @include res(padding-left, 13.7%, 2 / 13.7);
        @include res(padding-right, 13%, 2 / 13);
        &::after{
            content: '';
            display: block;
            height: 100%;
            background-color: $color-main-bg;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            @include res(width,96.875%,(xs:100%));
        }
        .left-img{
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            @include res(display,block,(sm:none));
            @include res(width,36%);
            @include res(margin-top,-30%);
            img{
                display: block;
                width: 100%;
                height: auto;
                &.zhanwei{
                    opacity: 0;
                    visibility: hidden;
                }
                &.wy{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    display: block;
                }
            }
        }
        .right-icon{
            flex-shrink: 0;
            @include res(width,56.7%,(sm:100%));
            ul{
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                li{
                    flex-shrink: 0;
                    text-align: center;
                    @include res(width,22%);
                    &:hover{
                        .item-img{
                            &::after{
                                opacity: 1;
                            }
                        }
                        .t{
                            color: $color-main-brown;
                        }
                    }
                    .item-img{
                        background-color: #fff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        overflow: hidden;
                        max-width: 155px;
                        width: 100%;
                        position: relative;
                        @include res(margin-bottom,40px, 20 / 40);
                        // @include res(width,155px, 130 / 155);
                        // @include res(height,155px, 130 / 155);
                        &::after{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(#fff,#fff,#f6ede1);
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            transition: all .3s;
                            opacity: 0;
                        }
                        img{
                            display: block;
                            width: 100%;
                            height: auto;
                            position: relative;
                            z-index: 1;
                        }
                    }
                    .t{
                        color: $color-main-green;
                        transition: all .3s;
                        @include res(font-size,20px, 16 / 20);
                    }
                }
            }
        }
    }
}

.index_pro {
	background-color: #157574;
    position: relative;
    overflow: hidden;
    @include res(display,block,(sm:none));
    &.fixed {
        // .color-bg,
        .text-cont {
            position: fixed;
        }
    }
    &.abs {
        // .color-bg,
        .text-cont {
            position: absolute;
            // bottom: -288px;
            top: auto;
            // bottom: -180px;
            @include res(bottom, -288px, 0 / -288);
        }
    }
    .title {
        @include res(padding-top, 130px);
        @include res(margin-left,6.45%);
        h2 {
            font-weight: bold;
            @include res(font-size,40px, 26 / 40);
            color: #fff;
        }
    }

    // .color-bg {
    //     height: 100vh;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: -1;
    //     transition: all .45s;
    //     background: $color-main-green;
    //     @include res(width, 100%);
    // }

    .text-cont {
        height: 100vh;
        position: absolute;
        z-index: 1;
        color: #fff;
        @include res(top, 288px, 0 / 288);
        // @include res(top, 150px);
        @include res(width, 28.33%,(mmd:32%));
        @include res(right, 130px, (lg:100px,mmd:60px,md:40px,sm:20px));
        .pro-page {
            // border-bottom: 1px solid #fff;
            position: relative;
            display: flex;
            justify-content: space-between;
            @include res(height,46px,(lg:46px));
            overflow: hidden;
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 1px;
              background-color: #fff;
              position:absolute;
              bottom: 0;
              left: 0;
              transform: scaleY(.5);
            }
            .title-line {
                position: absolute;
                display: block;
                @include res(top, 44px, (lg:44px));
                left: 0;
                width: 100%;
                height: 2px;
                background: #fff;
            }
            .page-title {
                transition: all .3s;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
            h2{
                font-weight: bold;
                @include res(font-size,22px, 18 / 22);
                @include res(padding-bottom, 10px);
                @include res(padding-top, 10px);
            }
            .line {
                width: 100%;
                height: 1px;
                background: #fff;
                position: absolute;
                bottom: -1px;
            }
            .nth {
                font-family: DIN-Medium;
                @include res(font-size,20px, 16 / 20);
                color: #fffefe;
                display: flex;
                @include res(margin-top, 10px);
                .first {
                    transition: all .3s;
                    span {
                        display: block;
                        height: 50px;
                    }
                }
                .second {
                    span {
                        opacity: .2;
                    }
                }
                .third {
                    span {
                        opacity: .2;
                    }
                }
            }
        }
        .item {
            width: 100%;
            position: absolute;
            transition: all .3s;
            color: #fff;
            top: 130px;
            opacity: 0;
            visibility: hidden;
            h2 {
                line-height: 1.6;
                @include res(margin-bottom, 20px);
                @include res(font-size, 28px, 22 / 28);
            }
            p {
                @include res(font-size, 16px, 14 / 16);
                @include res(line-height,2, 1.6 / 2);
            }
            a {
                float: right;
                margin-top: 55px;
                display: block;
                @include res(width,300px);
                @include res(height, 93px);
                background-color: #ffffff;
                border-radius: 6px;
                box-sizing: border-box;
                @include res(padding-top, 20px);
                @include res(padding-bottom, 20px);
                @include res(padding-left, 20px);
                @include res(padding-right, 20px);
                color: #157574;
                @include res(font-size,14px, 12 / 14);
                span {
                    display: block;
                    transition: all .3s;
                }
                i {
                    transition: all .3s;
                    @include res(font-size, 36px);
                    position: absolute;
                    bottom: 0;
                    @include res(right,20px);
                }
                &:hover {
                    span {
                        transform: translateX(10px);
                    }
                    i {
                        transform: translateX(-10px);
                    }
                }
            }
            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .img-cont {
        @include res(width,51.98%);
        @include res(margin-left, 6.45%);
        .item {
            position: relative;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            .pic-title {
                z-index: 1;
                @include res(left,7%);
                position: absolute;
                bottom: -50%;
                font-family: DIN-Medium;
                text-transform: uppercase;
                @include res(font-size,105px, 20 / 105);
                color: #fff;
                line-height: .8;
                h2 {
                    color: transparent;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke: 1px #fff;
                }
                h2,h3{
                  overflow: hidden;
                  display: flex;
                  span{
                    display: inline-block;
                    transition: transform .2s cubic-bezier(0,0,.22,.85);
                    transform: translateY(100%);
                  }
                }
                &.show{
                  h2,h3{
                    span{
                      transform: translateY(0);
                    }
                  }
                }
            }
            .img {
                width: 100%;
                background-position: center;
                background-size: cover;
                position: relative;
                overflow: hidden;
                background-color: #000;
                img {
                    // max-width: fit-content;
                    width: 100%;
                    height: auto;
                    display: block;
                    opacity: .8;
                }
            }
        }
    }
}

.index_pro-mod {
    @include res(display,none,(sm:block));
    background-color: #157574;
    .title {
        @include res(padding-top, 50px);
        margin-bottom: 30px;
        @include res(margin-left, 6.45%);
        h2 {
            font-weight: bold;
            @include res(font-size, 40px, 26 / 40);
            color: #fff;
        }
    }
    ul {
        padding-bottom: 30px;
        li {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            .pic {
                width: 90%;
                position: relative;
                margin: auto;
                img {
                    display: block;
                    width: 100%;
                    max-width: fit-content;
                }
                .pic-title {
                    transition: all 1.5s;
                    @include res(left,7%);
                    position: absolute;
                    bottom: 0;
                    font-family: DIN-Medium;
                    text-transform: uppercase;
                    @include res(font-size,30px);
                    @include res(line-height,26px);
                    color: #fff;
                    h2 {
                        color: transparent;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke: 1px #fff;
                    }
                }
            }
            .text {
                width: 90%;
                margin: auto;
                .text-title {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: #fff;
                    border-bottom: 1px solid #fff;
                    h2 {
                        font-size: 22px;
                        padding-bottom: 10px;
                        border-bottom: 2px solid #fff;
                    }
                }
                .content {
                    color: #fff;
                    h2 {
                        font-size: 20px;
                        margin-bottom: 10px;
                        line-height: 1.6;
                    }
                    p {
                        font-size: 14px;
                        line-height: 1.6;
                    }
                    a {
                    @include res(margin-top, 20px);
                    float: right;
                    display: block;
                    @include res(width,150px);
                    @include res(height, 50px);
                    background-color: #ffffff;
                    border-radius: 6px;
                    box-sizing: border-box;
                    @include res(padding-top, 10px);
                    @include res(padding-bottom, 10px);
                    @include res(padding-left, 10px);
                    @include res(padding-right, 10px);
                    color: #157574;
                    position: relative;
                    @include res(font-size,14px, 12 / 14);
                        i {
                        @include res(font-size, 30px);
                        position: absolute;
                        bottom: 0;
                        @include res(right,20px);
                        }
                    }
                }
            }
        }
    }
}

.loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $color-main-green;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .cont{
        position: relative;
        z-index: 10;
        color: #fff;
        @include res(margin-bottom, 200px, 100 / 200);
        p{
            @include res(margin-top, 45px, 15 / 45);
            span {
                display: inline-block;
                height: 1em;
                line-height: 1;
                text-align: left;
                vertical-align: -0.25em;/* 属性设置元素的垂直对齐方式。指定为负长度，可以使元素降低 */
                overflow: hidden;
                margin-left: 5px;
            }
            span::before{
                display: block;
                content: '...\A..\A.';
                white-space: pre-wrap;/* 保留空白符序列 */
                animation: span 3s infinite step-start both;
            }
            @keyframes span{
                33%{ transform: translateY(-2em); }
                66%{ transform: translateY(-1em); }
            }
        }
    }
    #bg_box{
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        opacity: .6;
    }
}